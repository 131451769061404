body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.map-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font-family: monospace;
  
  margin: 12px;
  border-radius: 4px;
}

a {
  color: white;
}



.infobar {
  position: absolute;
  bottom: 23px;
  left: -3px;
  z-index: 1;
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font-family: monospace;
  
  margin: 12px;
  border-radius: 4px;
}
